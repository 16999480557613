<template>
  <div>
    <van-popup
        v-model="showModify"
        position="bottom"
        :style="{ height: '100%' }"
        v-if="showModify"
    >
      <van-nav-bar title="创建放映报告" left-arrow fixed>
        <template #left>
          <van-icon
              name="arrow-left"
              size="24"
              color="#007aff"
              @click="showModify = false"
          ></van-icon>
        </template>
      </van-nav-bar>
      <div class="modifyPlan">
        <div>
          <van-cell>
            <template #title> 影片名称</template>
            <template #label>
              <div class="inputBox">
                <div>
                  {{ tempPlanData.films.map((item) => item.name).join('/') }}
                </div>
              </div>
            </template>
          </van-cell>
          <!-- 影片类型 -->
          <van-cell>
            <template #title>
              影片类型
            </template>
            <template #label>
              <div class="inputBox">
                <div>
                  {{
                    tempPlanData.films
                        .map((item) => item.type || '未知类型')
                        .join('/')
                  }}
                </div>
              </div>
            </template>
          </van-cell>
          <!-- 放映日期 -->
          <van-cell title="放映日期" center>
            <template #title>
              放映日期
            </template>
            <template #label>
              <div class="inputBox">
                <div>
                  {{ tempPlanData.playDate }}
                </div>
              </div>
            </template>
          </van-cell>
          <!-- 放映时间 -->
          <van-cell title="放映时间" center>
            <template #title>
              <span style="color: red">*</span>放映时间
            </template>
            <template #label>
              <div class="inputBox" @click="showStartTimeCalendar = true">
                <div class="inputBox-left" v-if="tempPlanData.playEndTime">
                  <span> {{ tempPlanData.playStartTime }} </span> -
                  <span> {{ tempPlanData.playEndTime }}</span>
                </div>
                <div
                    class="inputBox-left"
                    v-else
                    style="color: rgba(0, 0, 0, 0.2)"
                >
                  <span> 开始时间 </span> -
                  <span> 结束时间</span>
                </div>
                <van-icon name="clock" size="16"/>
              </div>
            </template>
          </van-cell>
          <van-cell title="现场照片">
            <template #title>
              <span style="color: red">*</span>现场照片(上传两张)
            </template>
            <template #label>
              <div class="film-img">
                <!-- <van-image
                  :src="item"
                  v-for="(item, index) in tempPlanData.fileList"
                  :key="index"
                  style="width: 20vw; margin: 5px"
                  fit="contain"
                  @click="previewImg"
                >
                  <van-icon
                    name="clear"
                    size="25"
                    color="red"
                    style="
                      position: absolute;
                      top: -5px;
                      right: -5px;
                      color: red;
                      cursor: pointer;
                    "
                    @click="deleteImg(index)"
                  ></van-icon>
                </van-image> -->
                <div
                    class="film-img-item"
                    v-for="(item, index) in tempPlanData.fileList"
                    :key="index"
                >
                  <van-image :src="item" fit="fill" @click="previewImg">
                  </van-image>
                  <div class="film-img-item-del" @click="deleteImg(index)">
                    <van-icon name="delete" size="16" color="#fff"></van-icon
                    >
                    <span>删除</span>
                  </div>
                </div>

                <van-icon
                    name="add-square"
                    size="60"
                    style="border-radius: 50px"
                    @click="uploadImg"
                    color="#007aff"
                    v-if="tempPlanData.fileList.length !== 2"
                ></van-icon>
              </div>
            </template>
            <template #right-icon></template>
          </van-cell>
          <!-- 观众人数 -->
          <van-cell title="观众人数" center @click="psersonCountPicker = true">
            <template #title>
              <span style="color: red">*</span>观众人数
            </template>
            <template #label>
              <div class="inputBox">
                <div>
                  {{ tempPlanData.audienceCount.text }}
                </div>
                <van-icon name="arrow-down" size="16"/>
              </div>
            </template>
          </van-cell>

          <!-- <van-field
            required
            label="观众人数"
            type="text"
            readonly
            placeholder="请输入观众人数"
            right-icon="arrow-down"
            @click="psersonCountPicker = true"
            v-model="tempPlanData.audienceCount.text"
          >
          </van-field> -->
          <!-- 观影场地类型 -->
          <van-cell
              title="观影场地类型"
              center
              @click="showPlayPlacePicker = true"
          >
            <template #title>
              <span style="color: red">*</span>观影场地类型
            </template>
            <template #label>
              <div class="inputBox">
                <div>
                  {{ tempPlanData.playPlace.text }}
                </div>
                <van-icon name="arrow-down" size="16"/>
              </div>
            </template>
          </van-cell>
          <!-- <van-field
            required
            label="观影场地类型"
            type="text"
            readonly
            placeholder="请选择放映场地类型"
            right-icon="arrow-down"
            @click="showPlayPlacePicker = true"
            v-model="tempPlanData.playPlace.text"
          >
          </van-field> -->
          <!-- <van-field
            label="放映地点"
            v-model="tempPlanData.myLocation.detailAddress"
            required
            type="textarea"
            placeholder="请点击下面地图点位按钮获取定位"
            readonly
            right-icon="location-o"
          /> -->
          <div>
            <tian-di-tu
                ref="tianDiTu"
                v-if="showTianDiTu"
                :point="tempPlanData.showPoint"
                :location="tempPlanData.myLocation"
                @update="updatePoint"
            ></tian-di-tu>
          </div>
          <van-field
              required
              label=" 放映解码卡号"
              placeholder="请先输入编码后四位搜索选择"
              right-icon="arrow-down"
              readonly
              @click="$refs.cinemaCompanyCardCodePicker.show()"
              v-model="tempPlanData.selectedEquipment"
          >
          </van-field>
          <van-field
              v-model="tempPlanData.filmteamName"
              readonly
              required
              label="放映员"
              placeholder="请输入放映员姓名"
              type="text"
          />
          <div>
            <van-button
                class="btn"
                type="info"
                size="small"
                block
                @click="savePlan"
            >
              确认
            </van-button>
          </div>
        </div>
      </div>
      <pre v-isDev>
        {{ tempPlanData }}
      </pre>
    </van-popup>
    <!-- // 开始时间选择器弹窗 -->
    <van-popup
        v-model="showStartTimeCalendar"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-datetime-picker
          v-model="tempStartTime"
          type="time"
          @confirm="onConfirmStartTime"
          @cancel="showStartTimeCalendar = false"
          :filter="filterTime"
          title="选择放映开始时间"
      />
    </van-popup>
    <!-- // 结束时间选择器弹窗 -->
    <van-popup
        v-model="showEndTimeCalendar"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-datetime-picker
          v-model="tempEndTime"
          type="time"
          :filter="filterTime"
          @confirm="onConfirmEndTime"
          @cancel="showEndTimeCalendar = false"
          title="选择放映结束时间"
      />
    </van-popup>
    <!-- // 观众人数选择器弹窗 -->
    <van-popup
        v-model="psersonCountPicker"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-picker
          :columns="psersonCountRangeColumns()"
          title="选择观众人数"
          @confirm="confirmPersonCount"
          show-toolbar
          @cancel="psersonCountPicker = false"
      />
    </van-popup>
    <!-- 场地类型 -->
    <van-popup
        v-model="showPlayPlacePicker"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-picker
          :columns="playPlace"
          title="选择放映场地类型"
          @confirm="confirmPlayPlace"
          show-toolbar
          @cancel="showEquipmentPicker = false"
      />
    </van-popup>
    <!-- // 放映设备选择器弹窗 -->
    <cinema-company-card-code-picker
        ref="cinemaCompanyCardCodePicker"
        @confirm="confirmEquipment"
    >
    </cinema-company-card-code-picker>
  </div>
</template>

<script>
const Base64 = require('js-base64').Base64
import {Dialog, ImagePreview, Toast} from 'vant'
import {api} from '@/api'
import tianDiTu from './tianditu.vue'
import config from '@/config'
import cinemaCompanyCardCodePicker from '@/components/cinemaCompanyCardPicker/index.vue'

export default {
  name: 'EditPlan',
  components: {
    tianDiTu,
    cinemaCompanyCardCodePicker,
  },
  data() {
    return {
      waterText: '水印', // 水印文字
      showModify: false, // 控制修改弹窗的显示
      showStartTimeCalendar: false, // 控制开始时间选择器的显示
      showEndTimeCalendar: false, // 控制结束时间选择器的显示
      psersonCountPicker: false, // 控制观众人数选择器的显示
      showEquipmentPicker: false,
      showPlayPlacePicker: false, // 控制场地类型选择器的显示
      showTianDiTu: false, // 控制地图显示
      minTime: '06:00', // 最小放映时间
      maxTime: '24:00', // 最大放映时间
      reason: '', // 修改原因
      // 放映设备筛选key
      equipmentKey: '',
      // 放映设备选项
      equipmentOptions: [],
      playPlace: config.playPlace,
      tempStartTime: '', // 临时存储开始时间
      tempEndTime: '', // 临时存储结束时间
      tempPlanData: {
        myLocation: {
          detailAddress: '',
        },
        playPlace: config.playPlace[0],
        showPoint: {
          address: '',
        },
        films: [],
        playStartTime: '',
        playEndTime: '',
        equipment: [], // 存储选中的放映设备
        audienceCount: {text: '', value: ''},
        // 放映员姓名
        filmteamName: '',
        selectedEquipment: null,
      }, // 临时存储修改的计划数据
      from: {
        // 用于提交表单
        id: '',
      },
    }
  },
  mounted() {
    // 获取当前用户定位信息
    // this.DialogLog(Base64.encodeURI('fangsong'))
    console.log({config})
  },
  methods: {
    // 显示修改弹窗并初始化数据
    show(plan) {
      // console.log('当前编辑的放映计划:', plan)
      this.modifyPlan(plan)
    },
    previewImg() {
      console.log('预览图片')
      ImagePreview([...this.tempPlanData.fileList])
    },

    // 生成观众人数范围选项
    psersonCountRangeColumns() {
      return config.audienceRange
    },

    // 过滤时间选项
    filterTime(type, options) {
      if (type === 'hour') {
        return [
          6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          24,
        ]
      } else if (type === 'minute') {
        return [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
      }
      return options
    },

    // 初始化修改计划的数据
    modifyPlan(plan) {
      this.showModify = true
      this.tempPlanData = {
        ...this.tempPlanData,
        ...JSON.parse(JSON.stringify(plan)),
      }
      this.tempPlanData.reason = ''
      this.tempPlanData.playDate = this.tempPlanData.playDatetime.split(' ')[0]
      const [startTime, endTime] = this.tempPlanData.playDatetime
          .split(' ')[1]
          .split('-')
      // this.tempPlanData.playStartTime = startTime.slice(0, 5)
      // this.tempStartTime = startTime
      this.tempPlanData.audienceCount = config.audienceRange[0]
      this.tempPlanData.playEndTime = endTime || ''
      this.showTianDiTu = true
      this.tempPlanData.fileList = []
      console.log(this.tempPlanData)
    },
    // 选择观影人数
    confirmPersonCount(option, index) {
      console.log('选择观众人数', option, index)
      console.log(this.psersonCountRangeColumns()[index])

      this.tempPlanData.audienceCount = this.psersonCountRangeColumns()[index]
      this.psersonCountPicker = false
    },

    // 确认开始时间
    onConfirmStartTime(time) {
      this.tempPlanData.playStartTime = time
      this.showStartTimeCalendar = false
      // this.updatePlayDatetime()
      // this.tempEndTime = time
      this.tempPlanData.playEndTime = ''
      this.showEndTimeCalendar = true
    },
    // 确认放映场地类型
    confirmPlayPlace(option) {
      console.log('选择放映场地类型', option)
      this.tempPlanData.playPlace = option
      this.showPlayPlacePicker = false
    },

    // 确认结束时间
    onConfirmEndTime(time) {
      if (time < this.tempStartTime) {
        Toast('结束时间不能早于开始时间')
        return
      }
      this.tempPlanData.playEndTime = time
      this.showEndTimeCalendar = false
      this.updatePlayDatetime()
    },
    updatePoint(point) {
      // this.DialogLog(point)
      this.tempPlanData.myLocation = point
      // console.log('更新当前位置',point);
    },
    confirmEquipment(value) {
      console.log('选择的设备编码', value)
      this.tempPlanData.selectedEquipment = value
    },

    // 更新放映日期时间
    updatePlayDatetime() {
      this.tempPlanData.playDatetime = `${this.tempPlanData.playDate} ${this.tempPlanData.playStartTime}-${this.tempPlanData.playEndTime}`
    },
    uploadImg() {
      console.log('上传图片')
      if (this.tempPlanData.fileList.length >= 2) {
        Toast('最多只能上传2张图片')
        return
      }
      //上传图片
      ZWJSBridge.chooseImage({
        upload: true,
      })
          .then((res) => {
            // console.log(result,picPath)
            const picUrl = res.picPath[0]
            // oss 图片水印后缀
            this.tempPlanData.fileList.push(this.watermarkImg(picUrl))
            this.$forceUpdate()
          })
          .catch((error) => {
            this.DialogLog(error)
          })
    },
    //  水印后缀封装
    watermarkImg(picUrl) {
      // this.DialogLog(this.tempPlanData.myLocation)
      this.waterText =
          this.tempPlanData.filmteamName + '-' +
          this.moment().format('YYYY-MM-DD HH:mm:ss')
      const watermark = Base64.encodeURI(this.waterText)
      // 文字水印
      return `${picUrl}?x-oss-process=image/watermark,type_d3F5LXplbmhlaQ,size_100,text_${watermark},color_FFFFFF,shadow_50,t_100,g_se,x_10,y_10`
    },
    // 删除图片
    deleteImg(index) {
      this.tempPlanData.fileList.splice(index, 1)
      this.$forceUpdate()
    },
    // 校验提交数据
    validatePostData(postData) {
      const errors = []
      console.log('校验提交数据', postData)

      // 校验放映结束时间和开始时间
      if (!postData.playEndTime || !postData.playStartTime) {
        errors.push('放映结束时间和开始时间不能为空')
      } else {
        const startTime = new Date('1970-01-01T' + postData.playStartTime)
        const endTime = new Date('1970-01-01T' + postData.playEndTime)
        if (startTime >= endTime) {
          errors.push('放映开始时间必须早于结束时间')
        }
      }



      // 校验现场照片
      if (!postData.pictures || postData.pictures.length !== 2) {
        errors.push('请上传2张现场照片')
      }

      // 校验解码设备编码
      if (
          !postData.cinemaCompanyCardCode ||
          typeof postData.cinemaCompanyCardCode !== 'string'
      ) {
        errors.push('请选择解码设备编码')
      }


      // 校验位置经纬度
      if (
          typeof postData.lat !== 'number' ||
          typeof postData.lng !== 'number'
      ) {
        errors.push('位置的经度和纬度必须是数字')
      }

      // 校验放映地点
      if (!postData.remark || typeof postData.remark !== 'string') {
        errors.push('放映地点必须是一个包含地点信息的对象')
      }
      return errors
    },
    // 保存修改的计划
    savePlan() {
      console.log('保存修改的计划', this.tempPlanData)

      const postData = {
        attendanceNum: this.tempPlanData.audienceCount.value, // 观影人数
        cinemaCompanyCardCode: this.tempPlanData.selectedEquipment, // 解码设备编码
        cinemaCompanyMachineCode: 'string', // 播放设备编码
        filmIds: this.tempPlanData.filmIds, // 影片ID
        filmPlanId: this.tempPlanData.id, // 影片计划ID
        lat: this.tempPlanData.myLocation.latitude,
        lng: this.tempPlanData.myLocation.longitude,
        remark: JSON.stringify(this.tempPlanData.myLocation), // 放映地点
        pictures: this.tempPlanData.fileList, // 现场照片
        playDate: this.tempPlanData.playDate,
        playEndTime: this.tempPlanData.playEndTime? this.tempPlanData.playEndTime + ':00' : '',
        playStartTime: this.tempPlanData.playStartTime? this.tempPlanData.playStartTime + ':00' : '',
        showPointId: this.tempPlanData.showPoint.id, //   放映地点ID
        playPlace: this.tempPlanData.playPlace.value, // 放映场地类型
      }
      // 使用校验函数
      const errors = this.validatePostData(postData)
      if (errors.length > 0) {
        console.error('数据校验失败:', errors)
        Dialog.alert({
          message: errors[0],
          title: '提示',
        })
      } else {
        console.log('数据校验通过')

        // this.DialogLog(postData)
        Dialog.confirm({
          message: '确定要提交放映报告吗？',
          title: '提示',
        }).then(() => {
          api.filmmaker
              .createReport(postData)
              .then((res) => {
                const {success, message} = res
                if (!success) {
                  Dialog.alert({
                        message: message,
                        title: '提示',
                      }
                  )
                } else {
                  Dialog.alert({
                    message: '提交成功',
                  }).then(() => {
                    this.showModify = false
                    this.$emit('update')
                  })
                }
                console.log(res)
              })
              .catch((error) => {
                this.DialogLog(error)
                console.log(error)
              })
        })
      }
    },
    DialogLog(log) {
      Dialog.alert({
        message: JSON.stringify(log),
        title: '提示',
      })
    },
  },
}
</script>

<style scoped lang="less">
.modifyPlan {
  background-color: #f6f7f9;
  padding: 4px;
  margin-top: 10vw;
  font-size: @font-size-medium;
}

.inputBox {
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e8e9ec;
  padding: 18px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-left {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 30vw;
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.film {
  display: flex;
  align-items: center;

  &-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
    position: relative;

    &:before {
      content: '';
      border: 1px solid @color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }

  &-img {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &-item {
      width: 20vw;
      position: relative;
      box-sizing: border-box;
      margin-right: 5px;

      &-del {
        display: flex;
        position: absolute;
        justify-content: flex-end;
        align-items: center;
        padding: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        width: 20svw;
        bottom: 5px;
        z-index: 9999;
      }
    }
  }

  &-title {
    padding: 12px;
    background: #fff;
    border-radius: 4px;
    width: 70vw;
    color: #686b73;
  }
}

::v-deep .van-cell {
  background-color: #f6f7f9;
  padding: 10px 16px;
}
</style>
