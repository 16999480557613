<!--
 * @Date: 2024-09-18 15:05:25
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-16 15:16:41
 * @FilePath: \zyt-mobile-frontend\src\views\me\screenReports\modules\tianditu.vue
-->
<template>
  <div class="tianditu-container">
    <van-notice-bar
      text="如果放映点位置有误差请联系您的放映公司进行调整"
      left-icon="info-o"
      color="#1989fa"
      background="#ecf9ff"
    />
    <div id="tiandituMap" ref="tiandituMap" class="tianditu-map">
      <div class="refresh-button" @click="relocate">
        <van-icon
          name="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d2235fe1e.png"
          size="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
export default {
  name: 'TiandituMap',
  props: {
    // 地图中心点坐标
    point: {
      type: Object,
      default: () => ({ lat: 39.9042, lng: 116.4074 }), // 北京默认坐标
    },
    location:{
      type: Object,
      default: () => ({ lat: 39.9042, lng: 116.4074,detailAddress:'未知地址' }),
    }
  },
  data() {
    return {
      mapCenter: this.center,
      currentPosition:null,// 当前定位
      // 当前定位
      currentPositionMarker: null,
      // 当前定位标记
      currentPositionLabel: null,
      map: null,
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      // 初始化天地图
      let T = window.T
      // 1.创建天地图主界面
      this.map = new T.Map(this.$refs.tiandituMap)
      // 设置显示地图的中心点和级别
      this.map.centerAndZoom(new T.LngLat(this.point.lng, this.point.lat), 13)
      // 在地图中心添加标记
      const centerMarker = new T.Marker(
        new T.LngLat(this.point.lng, this.point.lat),
      )
      this.map.addOverLay(centerMarker)

      // 添加标记的文字说明
      const centerLabel = new T.Label({
        text: '放映点:' + this.point.name,
        position: new T.LngLat(this.point.lng, this.point.lat),
        offset: new T.Point(-50, -50),
      })
      this.map.addOverLay(centerLabel)

      // 绘制1.5公里的圆
      const circle = new T.Circle(
        new T.LngLat(this.point.lng, this.point.lat),
        1500, // 半径，单位为米
        {
          color: 'blue',
          weight: 2,
          opacity: 0.8,
          fillColor: '#FFFFFF',
          fillOpacity: 0.1,
        },
      )
      this.map.addOverLay(circle)
      // 添加点击事件监听
      // this.map.addEventListener('click', this.onMapClick)
      // 获取当前定位
      ZWJSBridge.getLocation()
        .then((position) => {
          this.$emit('update', position)
          // 更新地图中心点
          this.mapCenter = {
            lat: position.latitude,
            lng: position.longitude,
          }
          // // 将地图中心移动到当前位置
          this.map.panTo(new T.LngLat(this.mapCenter.lng, this.mapCenter.lat))

          // // 在当前位置添加标记
          this.currentPositionMarker = new T.Marker(
            new T.LngLat(this.mapCenter.lng, this.mapCenter.lat),
          )
          this.map.addOverLay(this.currentPositionMarker)
          // 设置标记的提示信息
          this.currentPositionLabel = new T.Label({
            text: '当前位置：'+this.location.detailAddress,
            position: new T.LngLat(this.mapCenter.lng, this.mapCenter.lat),
            offset: new T.Point(-50, -55),
          })
          this.map.addOverLay(this.currentPositionLabel)
        })
        .catch((error) => {
          console.error('获取当前位置失败:', error)
          Dialog.alert({
            message: '无法获取当前位置，请检查定位权限',
          }).then(() => {
            // 重新初始化地图
            this.relocate()
          })
        })
    },
    // 重新定位
    relocate() {
      ZWJSBridge.getLocation()
        .then((position) => {
          //   // 更新地图中心点
            this.mapCenter = {
              lat: position.latitude,
              lng: position.longitude,
            }
            this.$emit('update', position)
          //   // 将地图中心移动到当前位置
            this.map.panTo(new T.LngLat(this.mapCenter.lng, this.mapCenter.lat))

           // 移除旧的标记和标签
            this.map.removeOverLay(this.currentPositionMarker)
            this.map.removeOverLay(this.currentPositionLabel)

          //   // 在当前位置添加新标记
            this.currentPositionMarker = new T.Marker(
              new T.LngLat(this.mapCenter.lng, this.mapCenter.lat)
            )
            this.map.addOverLay(this.currentPositionMarker)

          //   // 设置新的标记提示信息
            this.currentPositionLabel = new T.Label({
              text: '当前位置',
              position: new T.LngLat(this.mapCenter.lng, this.mapCenter.lat),
              offset: new T.Point(-50, -55),
            })
            this.map.addOverLay(this.currentPositionLabel)

          Toast('定位成功')
        })
        .catch((error) => {
          // console.error('重新定位失败:', error)
          Dialog.alert({
            message: '重新定位失败，请检查定位权限'
          }).then(() => {
            // this.relocate()
            console.error('重新定位失败:', error)
          })
        })
    },
    open() {
      this.$nextTick(() => {
        this.map.invalidateSize()
      })
    },
  },
}
</script>

<style scoped>
.tianditu-container {
  width: 100%;
  height: 100%;
}
.tianditu-map {
  width: 100%;
  height: calc(40vh - 46px); /* 减去导航栏的高度 */
  position: relative;
}
.refresh-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
}
</style>
