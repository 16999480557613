<template>
  <div class="page">
    <!--    放映计划-->
    <div class="planList">
      <div class="plan-filter">
        <van-dropdown-menu>
          <van-dropdown-item @change="onSelectPlanStatus" v-model="planStatusFilter" :options="planStatus"/>
          <van-dropdown-item
              @change="onSelectPlanDate"
              v-model="timeRange"
              :options="planDates"
          />
          <van-dropdown-item
              @change="onSelectPoint"
              v-model="selectPoint"
              :options="pointList"
          />
        </van-dropdown-menu>
      </div>

      <van-cell
          class="planItem box"
          v-for="(plan, index) in tempPlanData"
          :key="index"
      >
        <div
            class="planItem-header status"
        >
          <div class="planItem-dateTime">
            {{ plan.playDatetime | formatDate }}
          </div>
          <div class="planItem-address">
            <span>
              <van-icon name="location" size="20px" color="#50baff"></van-icon>
            </span>
            <span style="max-width: 85vw">
              {{ plan.showPoint.name }}({{ plan.showPoint.address }})
            </span>
          </div>
        </div>
        <div class="planItem-films">
          <div class="planItem-films-header">
            <div>顺序</div>
            <div>影片名称</div>
          </div>
          <div
              class="planItem-films-body"
              v-for="(film, index) in plan.films"
              :key="index"
          >
            <div>
              <span :class="['mark' + (index + 1)]">{{ index + 1 }}</span>
            </div>
            <div>
              {{ film.name }}
            </div>
          </div>
        </div>
        <!--         {{-->
        <!--          `planStatus:${plan.planStatus} report:${plan.report} report.status :${-->
        <!--            plan.report ? plan.report.status : null-->
        <!--          }`-->
        <!--        }}-->
        <div class="planItem-btns">
          <div>
            <!-- 创建 planStatus = 1 && report = null
            校验中 planStatus = 4 && report != null && report.status = 0
            校验中 planStatus = 4 && report != null && report.status = 4
            校验失败 planStatus = 4 && report != null && report.status = 1
            审核中 planStatus = 4 && report != null && report.status = 2
            已审核 planStatus = 4 && report != null && report.status = 3 -->
            <van-button
                type="info"
                size="small"
                plain
                round
                block
                @click="modifyPlan(plan)"
                icon="edit"
                v-if="plan.planStatus === 1 && plan.report == null"
            >
              创建报告
            </van-button>
            <van-button
                type="info"
                size="small"
                plain
                round
                block
                :disabled="plan.report != null && plan.report.status !== 4"
                @click="toPlanDetail(plan)"
                icon="loading"
                v-if="
                plan.planStatus === 4 &&
                plan.report != null &&
                plan.report.status === 0
              "
            >
              校验中
            </van-button>
            <van-button
                type="danger"
                size="small"
                plain
                round
                block
                @click="toPlanDetail(plan)"
                icon="loading"
                v-if="
                plan.planStatus === 4 &&
                plan.report != null &&
                plan.report.status === 1
              "
            >
              校验失败
            </van-button>
            <van-button
                type="info"
                size="small"
                plain
                round
                block
                @click="toPlanDetail(plan)"
                icon="close-circle"
                v-if="
                plan.planStatus === 4 &&
                plan.report != null &&
                plan.report.status === 4
              "
            >
              待确认
            </van-button>
            <van-button
                type="info"
                size="small"
                plain
                round
                block
                @click="toPlanDetail(plan)"
                icon="clock"
                v-if="plan.planStatus === 4&&plan.report!=null&&plan.report.status===2"
            >
              审核中
            </van-button>
            <van-button
                type="info"
                size="small"
                plain
                round
                block
                disabled
                v-if="plan.planStatus === 4&&plan.report!=null&&plan.report.status===3"
            >
              已审核
            </van-button>
          </div>
        </div>
      </van-cell>
      <van-empty v-if="filterReport().length === 0">
        <div class="empty-block-text">暂无放映任务</div>
      </van-empty>
    </div>
    <van-popup v-model="showPoint" round position="bottom">
      <van-picker
          show-toolbar
          :columns="pointList"
          @cancel="showPicker = false"
          @confirm="onConfirmPoint"
      />
    </van-popup>
    <edit ref="edit" @success="onLoad" @update="onLoad"></edit>
  </div>
</template>

<script>
import {Toast} from 'vant'
import {api} from '@/api/index'
import Edit from './modules/edit.vue'

export default {
  name: 'screenPlansView',
  components: {
    Edit,
  },
  data() {
    return {
      plans: [],
      // 临时计划数据
      tempPlanData: {},
      cancelMessage: '',
      pageIndex: 0,
      showLoad: false,
      finished: false, // 是否加载完毕
      showPoint: false, // 显示选择放映点场地弹窗
      modifyMessage: '',
      selectPoint: null,
      // 选择时间跨度
      timeRange: null,
      planDates: [
        {text: '默认时间', value: null},
        {text: '今天', value: 1},
        {text: '本周', value: 7},
        {text: '本月', value: 30},
        {text: '90天内', value: 90},
      ],
      planStatusFilter: 0,
      query: {
        pageNo: 1,
        pageSize: 1000,
        // filmteamConfirmStatus: 1,
        isReport: true,
        startDate: this.moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
        endDate: this.moment().format('YYYY-MM-DD')
      },
      pointList: [
        {
          text: '全部',
          value: null,
        },
      ],
      planStatus: [
        {
          text: '全部状态',
          value: null,
        },
        {
          text: '待创建',
          value: 0,
        },
        {
          text: '校验中',
          value: 1,
        },
        // {
        //   text: '校验失败',
        //   value: 2,
        // },
        {
          text: '待确认',
          value: 3,
        },
        {
          text: '审核中',
          value: 4,
        },
        {
          text: '已审核',
          value: 5,
        },
      ],
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    // 加载数据 isUpdatePoint 是否更新点场地列表
    onLoad(isUpdatePoint = true) {
      api.filmmaker.getPlanConfirmList({...this.query}).then((res) => {
        const {result, total, success} = res
        console.log({res})
        if (success) {
          this.plans = result.records.map((plans) => {
            return {
              ...plans,
            }
          })
          this.tempPlanData = JSON.parse(JSON.stringify(this.filterReport()))
          this.finished = true
          this.showLoad = false
          if (isUpdatePoint) {
            this.updatePointList()
          }
        } else {
          this.showLoad = false
          Toast.fail(res.message)
        }
      })
    },
    updatePointList() {
      const plans = this.plans
      this.pointList = [
        {
          text: '全部',
          value: null,
        },
      ]
      plans.map((plan) => {
        const hasPoint = this.pointList.some(
            (item) => {
              return item.value !== null && plan.showPoint && item.value === plan.showPoint.id
            },
        )
        if (!hasPoint) {
          this.pointList.push({
            text: plan.showPoint.name || '未知场地',
            value: plan.showPoint.id,
            data: plan.showPoint,
          })
        }
      })
    },
    // 选择计划状态
    onSelectPlanStatus(value) {
      // this.planStatusFilter = value
      // this.onLoad()
      // this.filterReport()
      this.tempPlanData = JSON.parse(JSON.stringify(this.filterReport()))
    },
    // 选择时间跨度
    onSelectPlanDate(value) {
      // console.log({ value })
      switch (value) {
        case 1:
          this.query.startDate = this.moment().format('YYYY-MM-DD')
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 7:
          // 本周星期一
          this.query.startDate = this.moment()
              .startOf('week')
              .format('YYYY-MM-DD')
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 30:
          this.query.startDate = this.moment()
              .startOf('month')
              .format('YYYY-MM-DD')
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
        case 90:
          // -45
          this.query.startDate = this.moment()
              .subtract(89, 'days')
              .format('YYYY-MM-DD')
          this.query.endDate = this.moment()
              .format('YYYY-MM-DD')
          break
        default:
          this.query.startDate = this.moment()
              .subtract(30, 'days')
              .format('YYYY-MM-DD')
          this.query.endDate = this.moment().format('YYYY-MM-DD')
          break
      }
      this.onLoad()
    },
    // 选择放映点场地
    onSelectPoint(value) {
      console.log({value})
      this.selectPoint = value
      // this.showPoint = false
      this.query.showPointId = value
      this.onLoad(false)
    },
    /** 修改计划
     * @param {Object} plan
     */
    modifyPlan(plan) {
      this.$refs.edit.show(plan)
    },
    /** 取消计划
     * @param {Object} plan
     */
    cancelPlan(plan) {
      this.$refs.cancel.show(plan)
    },
    // 选择放映点场地
    onConfirmPoint(item) {
      this.query.showPointId = item.value
      this.onLoad(false)
    },
    // 跳转到放映报告详情页面
    toPlanDetail(plan) {
      console.log(plan)
      this.$router.push({
        path: `/screenReportDetail/${plan.report.id}`, // 假设 plan 对象中有一个 id 属性
      })
    },
    // 根据状态筛选报告
    filterReport() {
      let newPlans = []
      if (this.planStatusFilter === null) { // 全部状态
        newPlans = this.plans
      }// 逐一筛选不同状态的计划
      else if (this.planStatusFilter === 0) { // 待创建
        newPlans = this.plans.filter(plan => plan.report == null && plan.planStatus === 1);
      } else if (this.planStatusFilter === 1) { // 校验中
        newPlans = this.plans.filter(plan => plan.planStatus === 4 && plan.report != null && plan.report.status === 0);
      } else if (this.planStatusFilter === 2) { // 校验失败
        newPlans = this.plans.filter(plan => plan.planStatus === 4 && plan.report != null && plan.report.status === 1);
      } else if (this.planStatusFilter === 3) { // 待确认
        newPlans = this.plans.filter(plan => plan.planStatus === 4 && plan.report != null && plan.report.status === 4);
      } else if (this.planStatusFilter === 4) { // 审核中
        newPlans = this.plans.filter(plan => plan.planStatus === 4 && plan.report != null && plan.report.status === 2);
      } else if (this.planStatusFilter === 5) { // 已审核
        newPlans = this.plans.filter(plan => plan.planStatus === 4 && plan.report != null && plan.report.status === 3);
      }
      return newPlans
    },
  },
  filters: {
    // 2024-09-26 18:00:00   => 2024-09-26 星期一 18:00
    formatDate(value) {
      const date = new Date(value)
      const week = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
      const minute =
          date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
      return `${year}-${month}-${day} ${week[date.getDay()]} ${hour}:${minute}`
    },
  },
}
</script>

<style scoped lang="less">
.page {
  min-height: 80vh;
}

.box {
  width: 100%;
  //height: 283px;
  background: #ffffff;
  border-radius: @border-radius-base;
  padding: 0;
}

.planList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.plan-filter {
  width: 100%;
}

.planItem {
  border: 1px solid #e6ebf0;
  margin: 10px;

  &-header {
    width: 100%;
    max-height: 87px;
    background: url('./../../../assets/illustrations/card.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 0 0 4px 4px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: relative;
  }

  &-dateTime {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #363a44;
    padding-left: 25px;
    margin-top: 5px;
  }

  &-address {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363a44;
    //margin: 5px 0;
    display: flex;
    //超出换行
    & > span {
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &-films {
    width: 100%;
    margin: 0 auto;

    &-header {
      width: 100%;
      height: 34px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #244e8a;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      background: #f8f8f8;

      > div:first-child {
        width: 30%;
      }

      > div:last-child {
        width: 70%;
        text-align: center;
      }
    }

    &-body {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #244e8a;
      line-height: 40px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      text-align: center;
      border-bottom: 1px solid #f0f5fe;

      > div:first-child {
        width: 30%;
      }

      > div:last-child {
        width: 70%;
        text-align: center;
      }
    }
  }

  &-btns {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      flex: 1;
      padding: 5px 10px;
    }
  }
}

.mark1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #8fece7 0%, #56d5cb 100%);
  box-shadow: 0 2px 3px 0 #caf2ef;
  border-radius: 3px;
  margin: 0 auto;
}

.mark2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #abcbff 0%, #729cff 100%);
  box-shadow: 0 2px 3px 0 rgba(143, 185, 255, 0.4);
  border-radius: 3px;
  margin: 0 auto;
}

.mark3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  width: 16px;
  height: 18px;
  background: linear-gradient(180deg, #d3b0ff 0%, #d26eff 100%);
  box-shadow: 0 2px 3px 0 #f6d3ff;
  border-radius: 3px;
  margin: 0 auto;
}

.status {
  &::after {
    content: '';
    display: block;
    width: 57px;
    height: 57px;
    position: absolute;
    left: 80%;
    //background: url("./../../../assets/illustrations/1.png") no-repeat center center;
  }

  &-1::after {
    background: url('./../../../assets/illustrations/1.png') no-repeat center center;
  }

  &-2::after {
    background: url('./../../../assets/illustrations/3.png') no-repeat center center;
  }

  &-3::after {
    background: url('./../../../assets/illustrations/2.png') no-repeat center center;
  }
}

.cancelPlan {
  .star {
    font-family: PingFangSC, PingFang SC, sans-serif;
    color: red;
    font-size: 12px;
  }

  h4 {
    text-indent: 12px;
  }

  .btn {
    width: 80%;
    margin: 0 auto;
  }
}

.modifyPlan {
  margin: 5px;
  border: 1px solid #e6e6e6;
  border-radius: @border-radius-base;
  padding: 10px;

  h4 {
    margin: 5px 0;
    color: rgba(51, 51, 51);
  }

  .star {
    font-family: PingFangSC, PingFang SC, sans-serif;
    color: red;
    font-size: 12px;
  }

  .modifyPlan-films {
    //border: 1px solid #E6E6E6;
    width: 230px;

    li {
      display: flex;
      justify-content: start;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      height: 40px;
      text-indent: 5px;

      &:last-child {
        text-align: right;
        text-indent: 150px;
      }

      span {
        text-indent: 15px;
        display: inline-block;
      }
    }
  }
}
</style>
